import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
  Box,
  Badge,
  Text,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from '@wix/wix-ui-icons-common';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  const [client, setClient] = React.useState(null as any);

  const orderId = new URLSearchParams(window.location.search).get("orderId");
  const instance = new URLSearchParams(window.location.search).get("instance");

  const [shippingInfo, setShippingInfo] = React.useState(null as any);

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
    if (orderId)
    getOrderShippingInfo(orderId!).then((res) => {
      res.json().then((data) => {
        setShippingInfo(data);
      });
    });
  }, []);

  function getOrderShippingInfo(orderId: string) {
    return fetch(
      `https://api.wix.com/commerce/1/orders/${orderId}/shippingInfo`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${client.dashboard.auth().token}`,
        },
      }
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {/* <SidePanel skin="floating" width="100%"> */}
      {/* <SidePanel.Header title="SEO with AI"  subtitle="Get found on Google with ease"/> */}
      <Card>
        <MarketingLayout
          size="tiny"
          title="物流管理"
          description={
            <Box gap="SP1">
              <Text>{"在此對訂單的物流做出操作"}</Text>{" "}
              <Badge skin="warning">即將到來</Badge>
            </Box>
          }
          actions={
            <Box gap="SP1" direction="vertical">
              <Button size="small" disabled prefixIcon={<Icons.ShippingSmall/>} onClick={() => {}}>
                建立物流訂單
              </Button>
              <Button size="small" disabled prefixIcon={<Icons.RevertSmall/>} onClick={() => {}}>
                建立逆物流訂單
              </Button>
              <Button size="small" disabled prefixIcon={<Icons.PrintSmall/>} onClick={() => {}}>
                列印託運單
              </Button>
              <Button size="small" disabled prefixIcon={<Icons.EditSmall/>} onClick={() => {}}>
                編輯物流訂單
              </Button>
              <Button size="small" disabled prefixIcon={<Icons.Delete/>} onClick={() => {}}>
                删除物流訂單
              </Button>
              <Button
                size="small"
                priority="secondary"
                prefixIcon={<Icons.Chat/>}
                onClick={() => {
                  client.dashboard.navigate(
                    "08036415-9622-46a9-8f7a-9869075e4288"
                  );
                }}
              >
                獲取幫助
              </Button>
            </Box>
          }
        />
      </Card>
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
